<template>
<div class="register-wrapper">
   <div class="register-logo-area">
    <img src="http://wechatapppro-1252524126.file.myqcloud.com/appAKLWLitn7978/image/cmVzb3VyY2UtY291cnNlQXJ0aWNsZS0yMzA4MDI3NQ.png" class="register-logo-img" />
   </div> 
   <div class="register-input-list">
    <div class="register-input-item">
     <div class="phoneWrapper">
      <input type="text" hidden="hidden" /> 
      <div class="inputInfo">
       <p :class="focusValue==1?'subheadFocus subhead':'subhead'">手机号/账号</p> 
       <input maxlength="11" @blur="inputBlur" @focus="inputFocus(1)" autocomplete="on" data-sensors="注册_注册页-知识店铺_点击手机号输入框" type="tel" /> 
       <div class="inputBorder"></div>
      </div>
     </div>
    </div> 
    <div class="register-input-item">
     <div class="phoneWrapper invalidPhoneWrapper">
      <div class="inputInfo">
       <p :class="focusValue==2?'subheadFocus subhead':'subhead'">验证码</p> 
       <input maxlength="6" type="tel" @blur="inputBlur" @focus="inputFocus(2)"/> 
       <div class="inputBorder"></div> 
       <div class="verify-code-box">
        <span data-sensors="注册_注册页-知识店铺_获取验证码" class="blueBtn blueBtn__end">获取验证码</span> 
        <div data-v-7ef1d36d="" class="h5-toast" style="display: none;">
         <span data-v-7ef1d36d="">提示</span>
        </div>
       </div>
      </div>
     </div>
    </div> 
    <div class="register-input-item">
     <div class="phoneWrapper passwordWrapper">
      <div class="inputInfo">
       <p :class="focusValue==3?'subheadFocus subhead':'subhead'">设置密码：6-16位字符，包含字母和数字</p> 
       <input maxlength="16" @blur="inputBlur" @focus="inputFocus(3)" data-sensors="注册_注册页-知识店铺_点击设置密码" type="password" /> 
       <div class="inputBorder"></div> 
       <i class="eyes font_family icon-yanjing_bi"></i>
      </div>
     </div>
    </div>
   </div> 
   <div class="register-des">
    <a href="javascript:void(0)">去登录</a>
   </div> 
   <div data-sensors="注册_注册页-知识店铺_点击创建知识店铺" class="register-login-btn register-login-btn__end">
    <span>立即创建知识店铺</span>
   </div> 
   <div class="register-operate">
    <input id="remindPw" type="checkbox" data-sensors="注册_注册页-知识店铺_勾选小鹅通协议" class="filled-in" /> 
    <label for="remindPw" data-sensors="注册_注册页-知识店铺_勾选小鹅通协议">我已阅读并同意</label> 
    <a href="/charge_protocol_page" target="_blank"> 《<span>小鹅通服务协议</span>》 </a> 
    <span class="register-text">及</span> 
    <a href="/privacy_protocol_page" target="_blank"> 《<span>个人信息保护政策</span>》 </a>
   </div> 
   <div data-v-7ef1d36d="" class="h5-toast" style="display: none;">
    <span data-v-7ef1d36d="">提示</span>
   </div> 
  </div>
</template>
<script>
export default {
    name:'login',
    data(){
        return{
            focusValue:0,

        }
    },
    methods:{
        inputFocus(indx){
            this.focusValue = indx;
        },
        inputBlur(){
            this.focusValue = 0;
        }

    }
}
</script>
<style lang="scss" scoped>
input{
    -webkit-tap-highlight-color: transparent;
}
.register-wrapper {
    padding: .88rem .6rem 0;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    .register-logo-area{
        height: .96rem;
        margin-bottom: 1.18rem;
        text-align: center;
        img{
            height: .96rem;
            vertical-align: top;
        }
    }
    .register-input-list{
        .register-input-item{
            margin-bottom: .54rem;
            .phoneWrapper{
                color: #353535;
                position: relative;
                .inputInfo{
                    position: relative;
                    width: 100%;
                    height: .86rem;
                    font-size: .32rem;
                    margin-top: .6rem;
                    .subhead{
                        color: #999;
                        transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
                        font-size: .32rem;
                        margin: 0;
                        transform: translate3d(0, .3rem, 0) scale(1);
                        transform-origin: left top;
                    }
                    .subheadFocus{
                        transform: translate3d(0, -0.2rem, 0) scale(0.8);
                    }
                    input{
                        font-size: .32rem;
                        border: none;
                        outline: none;
                        box-shadow: none;
                        width: 100%;
                        display: block;
                        position: absolute;
                        left: 0;
                        top: .3rem;
                        background: transparent;
                        color: #333;
                        transition: all 0.2s linear;
                        -webkit-appearance: none;
                        padding: 0;
                    }
                    .inputBorder{
                        position: absolute;
                        width: 100%;
                        left: 0;
                        bottom: 0;
                        border-bottom: 1px solid #d4d9e2;
                    }
                }
            }
            .invalidPhoneWrapper{
                .verify-code-box{
                    position: absolute;
                    bottom: .2rem;
                    right: 0;
                    z-index: 1;
                    .blueBtn{
                        width: 1.68rem;
                        height: .64rem;
                        line-height: .64rem;
                        border-radius: .08rem;
                        border: 1px solid #105CFB;
                        color: #105CFB;
                        font-size: .24rem;
                        display: block;
                        text-align: center;
                    }
                    .blueBtn__end{
                        background-color: transparent;
                        color: #105CFB !important;
                    }
                    .h5-toast{
                        display: table;
                        height: .72rem;
                        /* line-height: 0.6rem; */
                        padding: 0 .64rem;
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        z-index: 100;
                        transform: translateX(-50%);
                        border-radius: 0.04rem;
                        background-color: rgba(0, 0, 0, 0.7);
                        color: #fff;
                        font-size: 0;
                        white-space: nowrap;
                        vertical-align: top;
                        span{
                            display: table-cell;
                            vertical-align: middle;
                            font-size: .28rem;
                        }
                    }

                }
                
            }
            .passwordWrapper{

            }
        }
       

    }
    .register-des{
        margin: 0;
        text-align: center;
        color: #105CFB;
        font-size: .28rem;
        text-align: right;
        a{
            text-decoration: none;
            color: #2a75ed;
        }
    }
    .register-login-btn{
        margin-top: .46rem;
        width: 100%;
        height: .88rem;
        line-height: .88rem;
        background: #105CFB;
        border-radius: .44rem;
        text-align: center;
        font-size: .32rem;
        color: #fff;
        letter-spacing: 3px;
    }
    .register-operate{
        width: 100%;
        font-size: .24rem;
        color: #C4C4C4;
        text-align: left;
        padding: .48rem 0;
        line-height: .4rem;
        input{
            background: #fff;
            cursor: pointer;
            // display: none;
        }
        [type="checkbox"] + label {
            position: relative;
            padding-left: .44rem;
            cursor: pointer;
            display: inline-block;
            font-size: .26rem;
            -webkit-user-select: none;
            -moz-user-select: none;
            -khtml-user-select: none;
            -ms-user-select: none;
            color: #666666;
            height: .32rem;
            line-height: .32rem;
            &:after{
                height: .28rem;
                width: .28rem;
                background-image: url('http://wechatapppro-1252524126.file.myqcloud.com/appAKLWLitn7978/image/cmVzb3VyY2UtY291cnNlQXJ0aWNsZS0yNzQxNzg1OA.png');
                background-size: cover;
                top: 1px;
                z-index: 0;
            }
        }
        a{
            color: #105CFB;
            font-size: .24rem;
            text-decoration: none;
        }
       
    }
}
    
</style>
